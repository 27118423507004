@mixin flexboxGridMixin($columnNumber, $margin, $marginBottom) {
  $nrOfSpaces: #{$columnNumber - 1};
  $contentPercent: calc(100% - #{$nrOfSpaces} * #{$margin}px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  > .product-block {
    box-sizing: border-box;
    width: calc(#{$contentPercent} / #{$columnNumber});
    margin-right: #{$margin}px;
    margin-bottom: #{$marginBottom}px;
    &:nth-child(#{$columnNumber}n) {
      margin-right: 0;
    }
  }
}

.ui-loader {
  display: none !important;
}

body#search.search-not-found {
  background: #fff;

  .searched-for {
    display: none;
  }

  .mm-page {
    background: #fff;

    #wrapper {
      background: #fff;

      #left-column {
        display: none;
      }

      #content-wrapper {
        width: auto;
        text-align: center;
        margin: 0 auto;

        .page-not-found {
          border: none;

          h4 {
            color: #2a527e;
            font-size: 36px;
            line-height: 0.89;
            font-weight: 500;
          }

          p {
            font-size: 14px;
            line-height: 3.57;
            color: #666;
          }

          #search_widget_none {
            form {
              max-width: 700px;
              margin: 0 auto;
            }

            .search-input {
              color: #2a527e;
              padding-left: 20px;
              padding-bottom: 4px;
            }

            button {
              background: #fff;
              margin-top: 2px;
            }

            .search-icon {
              background: url('../img/icons/search-icon.svg') no-repeat;
              width: 16px;
              height: 16px;
              background-color: #fff;
            }

            .close-icon {
              background: url('../img/icons/close-icon.svg') no-repeat;
              width: 16px;
              height: 16px;
              background-color: #fff;
              background-size: cover;
              display: inline-block;
              position: absolute;
              top: 13px;
              right: 40px;
            }
          }
        }
      }
    }

  }

  @media(max-width: 768px) {
    background: #f6f6f6;
    .mm-page {
      background: #f6f6f6;

      #wrapper {
        background: #f6f6f6;

        #content-wrapper {
          text-align: left;

          .page-not-found {
            background: #f6f6f6;

            h4 {
              font-size: 18px;
              text-transform: uppercase;
              line-height: 1.56;
              font-weight: bold;
            }

            p {
              font-size: 14px;
              line-height: 1.57
            }

            #search_widget_none {
              button {
                bottom: 6px;
                background-color: #f6f6f6;
              }

              .search-icon {
                background-color: #f6f6f6;
              }

              .search-input {
                background: #f6f6f6;
                border: solid 1px #a0a0a0;
              }
            }
          }
        }
      }
    }
  }
}

body#module-crd_history-historyproducts.search-not-found, body#module-crd_favorites-productlist.search-not-found {
  .account-searchbar, .page-header, .description, .file-download-wrapper {
    display: none;
  }
}

@media (min-width: map-get($grid-breakpoints, "lg")) and (max-width: map-get($grid-breakpoints, "xl")) {
  .container:not(.header-middle) {
    max-width: 100%;
  }
}

.custom-radio {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.custom-radio input[type="radio"]:checked + span {
  width: 8px;
  height: 8px;
  left: 1.2px;
  top: 1.2px;
}


.toggle-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
  padding: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid $dark-gray;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 1px;
    background-color: $dark-gray;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    border-color: $crd-primary-color;

    &:before {
      background-color: $crd-primary-color;
    }
  }

  input:checked + .slider,
  input:focus + .slider {
    box-shadow: 0 0 1px $crd-primary-color;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
    left: 3px;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 18px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.back-wrapper {
  display: none;
  width: 100%;
  height: 50px;
  padding: 0 30px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  background: #fff;

  p {
    font-size: 12px;
    line-height: 50px;
    margin: 0;
    background: url('../img/icons/arrow-back-wide.svg') no-repeat left center;
    padding-left: 40px;
    font-weight: bold;
    color: $crd-primary-color;
  }
}

.page-authentication {
  padding: 0;

  #main {
    max-width: 520px;
    margin: 0 auto;
    width: 100%;
  }

  #content {
    background: none;

    .forgot-password {
      padding: 15px 0 30px 0;
      margin: 0;
    }

    hr {
      margin: 0 0 30px 0;
    }
  }

  #main-login-form .form-footer {
    text-align: center;
  }
}

#module-crd_registration-registration .register-form,
.page-authentication #content {
  #main-login-form .btn,
  .form-control-submit:not(.new-btn-primary) {
    margin: 0 auto;
    min-width: 240px;
    max-width: 100%;
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0px 2px 4px rgba(30, 59, 90, 0.1);
    border-radius: 40px;
    line-height: 48px !important;
    height: 50px;
  }
}

.page-header {
  h1 {
    font-size: 24px;
    line-height: 36px;
    font-weight: normal;
    color: $dark-gray;
    font-family: GalaxiePolarisBook, sans-serif;
    text-transform: none;

    &:after {
      content: '';
      display: none;
    }
  }
}

.form-group {
  .form-control,
  select.form-control:not([size]):not([multiple]) {
    height: 50px;
    border: 2px solid $border-gray;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 10px;
    line-height: 50px;
  }

  .form-control.form-control-select {
    background: #fff;
  }

  .label.required, label.required,
  .label, label {
    font-size: 12px;
    text-transform: none;
    color: $dark-gray;
    line-height: 18px;
  }
}

.customer-form .form-control-label {
  font-size: 12px;
  text-transform: none;
  color: $dark-gray;
  line-height: 18px;
}

.show-password-btn {
  top: 10px;
}

#module-crd_registration-registration {
  .customer-form section {
    padding-bottom: 30px;
  }

  #register .form-footer {
    padding-top: 30px;
  }

  #main {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
  }
}

.img-responsive {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

#usp {
  width: 100%;
  background: #e4f4f1;
  height: 36px;
  line-height: 36px;

  .container {
    overflow: hidden;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      padding-right: 25px;
      color: #333333;
      font-size: 12px;
      line-height: 36px;
      padding-left: 32px;
      height: 36px;

      &:last-child {
        padding-right: 0;
      }

      background-size: 32px 32px;
      background-position-x: 0%;
      background-position-y: 50%;
      background-repeat: no-repeat;

      &.time {
        background-image: url('../img/icons/usp-time.png');
      }

      &.shipping {
        background-image: url('../img/icons/usp-shipping.png');
      }

      &.cart {
        background-image: url('../img/icons/usp-cart.png');
      }

      &.articles {
        background-image: url('../img/icons/usp-articles.png');
      }
    }
  }
}

.footer-container .block-newsletter.block_newsletter {
  padding: 30px 0;
  margin-bottom: 0;

  h2 {
    margin: 0 0 20px 0;
    color: #fff;
  }

  form {
    margin: 30px auto 0 auto;
    width: 100%;
    max-width: 450px;
  }

  .alert {
    margin-top: 0;
    padding-top: 0;

    &.alert-success {
      background: none;
      border: none;
      color: #d4edda;
    }

    &.alert-danger {
      color: #f8d7da;
      background: none;
      border: none;
    }
  }
}

.add-cart-redesign {
  .float-left {
    float: none !important;
  }
}

@media (max-width: 900px) {
  #usp {
    overflow: hidden;
  }

  #usp div {
    display: inline-block;
  }

  #loop {
    white-space: nowrap;
    animation: loop-anim 10s linear infinite;
  }

  @keyframes loop-anim {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -50% /* This works because of the div between "outer" and "loop" */
    }
  }
}

.boxed-design {
  a {
    display: inline-block;
    width: 100%;
  }

  h3 {
    text-align: center;
    color: #222;
    font-weight: 700;
    margin: 1.5rem 0 .75rem;
  }
}
