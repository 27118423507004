.fix-popup-button {
  line-height: 25px !important;
  font-weight: normal;
  text-transform: none;
}

@media (max-width: 400px) {
  #history .btn {
    width: 100px;
  }
}

#history .btn-primary {
  width: 170px;
  box-shadow: 0 25px 25px -17px #595959;
  font-weight: 400;
  height: 44px;
  border-radius: 22px;
  font-size: 17px;
  padding: 3px 20px;
  font-family: "GalaxiePolaris-Book", sans-serif;
}

#products, #product, #index {
  .product-miniature:not(.product_show_list) .product-title {
    margin-bottom: 10px;
  }
}

#product {
  .product-prices {
    .price-tax {
      position: relative;
      background: none;
      margin: 0;
      padding: 0;
    }
  }
}

#order-confirmation {
  .page-header {
    h1 {
      font-weight: bold;
      margin-left: 0;
    }
  }

  #content-hook_order_confirmation {
    p {
      margin-left: 0;
    }
  }

  #order-details {
    .invoice-download {
      justify-content: center;
      align-content: center;
      display: flex;
      padding: 15px 10px;
    }
  }
}

.file-download-btn-cont {
  a {
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

/**  CRD EVENT **/
#module-crd_event-event {
  .page-header {
    text-align: center;
  }

  #event-description {
    text-align: center;
    max-width: 900px;
    margin: 0 auto 20px auto;

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .event-register-form {
    .display-flex {
      display: flex;
      height: 50px;
      align-items: center;

      &.height-20 {
        height: 20px;
      }

      .radio-inline {
        margin-bottom: 0;
        margin-right: 10px;

        .custom-radio {
          margin-top: -3px;
          margin-right: 2px;
        }
      }
    }

    .white-bg {
      padding: 20px 10px;

      .inner-content {
        padding: 30px 70px;
        background: #fff;

        &.row-gutters-10 {
          .form-group {
            margin: 0;
          }

          .row {
            margin-right: -10px;
            margin-left: -10px;
            margin-top: 15px;

            div[class*="col-"] {
              padding-left: 10px;
              padding-right: 10px;
            }
          }

          > .row:first-child {
            margin-top: 0;
          }
        }
      }

      .nav-item .nav-link.active {
        color: $crd-primary-color;
      }

      #with-child, #with-partners {
        margin-top: 20px;
      }
    }
  }
}

header#header {
  z-index: 200;
}

.filter-box {
  .dropdown-menu {
    z-index: 199;
  }
}

.event-registration-wrapper {
  width: 100%;

  .event-register-form.registration-validation {
    .alert {
      width: 80%;
      margin: auto;
    }
  }
}

#history {
  .mm-page {
    #country-container {
      display: block !important;
    }
  }
}

#supercheckout-agree {
  .agree-terms:hover {
    cursor: pointer;
  }

  .agree-terms.us1000 {
    margin-left: -5px
  }
}

.half-banners {
  margin-top: 40px;
}

.pay-with-form {
  span.label {
    display: inline-block;
    white-space: break-spaces;
  }
}

#ui-datepicker-div {
  z-index: 10 !important;
}

#footer {
  border-top: 1px solid rgba(216, 215, 223, 0.2);
  background-color: rgba(216, 215, 223, 0.2);
}

#country-container .custom-country-select a.current-country:after {
  background: url('../img/icons/arrow-down-white.svg') no-repeat left center;
}

#country-container button.close-icon {
  background: url('../img/icons/close-icon.svg') no-repeat;
  background-size: cover;
}

.labels {
  display: block;
  width: max-content;
}

.iso-image {
  position: absolute;
  right: 15%;
  bottom: 20px;
  height: 90px;
  width: auto;
}

@media (max-width: 1370px) {
  .container-blockreassurance {
    padding-bottom: 10px;

    .unimportant {
      display: none;
    }

    .important {
      margin-left: auto;
      margin-right: auto;

      .block-title {
        padding-top: 2px;
        opacity: 1;
        font-size: 11px;
        font-weight: 400 !important;
        font-style: normal;
        letter-spacing: 0.34px;
        text-align: left;
        padding-left: 10px;
      }

      .block-icon {
        height: 16px !important;
        width: 16px;

        svg {
          height: 16px !important;
          width: 16px;
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .main-menu {
    display: none;
  }
}

@media (max-width: 1024px) {
  #module-crd_event-event {
    .event-register-form {
      .white-bg {
        padding: 20px;

        .inner-content {
          padding: 30px;
        }

        #with-child, #with-partners {
          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 996px) {
  .iso-image {
    position: inherit;
    height: 60px;
    width: auto;
    margin-left: 60px;
  }

  .block-social {
    display: inline-flex;
    justify-content: center;
  }
}

@media (max-width: 811px) {
  #order-confirmation .order-donwload-btns .file-download-btn-cont {
    width: 100% !important;
    margin-top: 15px !important;
    max-width: 100% !important;
  }
}

@media (max-width: 768px) {
  #module-crd_event-event {
    .event-register-form {
      .white-bg {
        padding: 20px;

        &.pr0 {
          padding-right: 0;
        }

        &.pl0 {
          padding-left: 0;
        }
      }
    }
  }
  .half-banners {
    margin-top: 20px;
  }
  #supercheckout-fieldset {
    .supercheckout-new #confirmCheckout {
      .sc-cart-summary .shipping .values {
        text-align: right;
      }
    }
  }
}

@media (max-width: 700px) {
  .container-blockreassurance {
    display: none;

    .block-icon {
      display: none;

      svg {
        display: none;
      }
    }
  }
}

@media (max-width: 500px) {
  #module-crd_event-event {
    .content-wrapper {
      padding: 0 20px;
    }

    #event-description {
      text-align: center;
      padding: 20px;

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .event-register-form {
      .white-bg {
        padding: 10px 20px;

        &.pr0 {
          padding-right: 20px;
        }

        &.pl0 {
          padding-left: 20px;
        }
      }
    }
  }
  .half-banners {
    &:first-child {
      margin-top: 0;
    }
    .img-responsive {
      margin-top: 20px;
    }
  }
}

@media (max-width: 767px) {
  #order-confirmation {
    .page-content.page-order-confirmation > .row {
      flex-direction: column-reverse;
    }

    #order-details ul li {
      margin: 5px 0;
    }

    .display-block {
      display: block;
    }

    #order-details .invoice-download {
      margin-left: 0;
    }
  }
  #graduationNotice .row span {
    line-height: 1;
    padding: 0 25px;
    font-size: 12px;
  }
}

@media (max-height: 590px) {
  .container-blockreassurance {
    display: none;

    .block-icon {
      display: none;

      svg {
        display: none;
      }
    }
  }
}

@media (min-width: 700px) {
  .blockreassurance {
    display: flex;
    justify-content: center;
  }
  .container-blockreassurance {
    padding-bottom: 12px;
    padding-top: 2px;

    .usp-padding {
      padding-right: 75px;
    }

    .block-title {
      padding-top: 2px;
      opacity: 1;
      font-size: 11px;
      font-weight: 400 !important;
      font-style: normal;
      letter-spacing: 0.34px;
      text-align: left;
    }

    .block-icon {
      height: 16px !important;

      svg {
        height: 16px !important;
        width: 16px;
        margin-right: 10px;
      }
    }
  }
}

@media (min-width: 1025px) {
  header#header.sticky {
    z-index: 200;
  }
}

@media (min-width: 768px) {
  .pi-additional-stars {
    display: inherit;
    text-transform: uppercase;
    position: absolute;
    right: 30px;
    top: 50px;
  }
}

.product-ratings-container {
  @media (max-width: 1024px) {
    .ministars-widget {
      flex-direction: column;
    }
  }
}

.product-rating {
  .rating-details {
    height: max-content !important;
  }

  div {
    margin-top: 0 !important;
  }

  .ekomi-widget-container .prc {
    max-width: max-content !important;
  }

  .ekomi-widget-container {
    display: inherit !important;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    .col-sm-4 {
      max-width: none !important;
    }

    .col-sm-8 {
      max-width: none !important;
    }

    .rating-details {
      display: flex;
      flex-direction: column;
    }

    .ekomi-widget-container {
      .col-sm-4 {
        width: inherit !important;
      }

      .col-sm-8 {
        width: inherit !important;
      }
    }
  }
}

.add-container {
  text-align: center;

  @media (min-width: 380px) {
    width: 350px;
  }
}

#not-available {
  display: block;
  color: red;
}

.video-banner-responsive {
  display: block;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}