@mixin search-box {
  form {
    position: relative;
    input[type=text] {
      &:focus {
        color: $gray-darker;
        background: white;
        outline: none;
        + button .search {
          color: white;
        }
      }
      border: none;
      padding: 8px 10px 8px 20px;
      padding-right: 40px !important;
      min-width: 255px;
      background: white;
      color: $gray;
      border-radius: 17px;
      margin-top: 7px;
      input::placeholder {
        color: $gray;
        text-indent: 0;
      }
    }
    button[type=submit] {
      position: absolute;
      background: none;
      border: none;
      bottom: rem(4px);
      right: rem(2px);
      color: white;
      background: #3cb7e0;
      border-radius: 50%;
      padding: 3px 3px;
      margin: 0 2px 0;
      width: 28px;
      height: 28px;
      .search:hover {
        color: white;
      }
      .search {
        font-size: 22px;
      }
      .search:focus {
        outline: none;
      }
    }
  }
}

@mixin box-shadow {
  box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.1);
}

@mixin special-border {
  font-size: 16px;
  font-weight: 500;
  color: $crd-primary-color;
  margin: 0 0 15px 0;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-family: NeoSansProMedium, sans-serif;
  &:after {
    content: "";
    background: #e6e6e6;
    position: absolute;
    top: 25px;
    left: 0;
    height: 1px;
    width: 50px;
  }
}

@include media-breakpoint-down(sm) {
  #search_widget form input[type=text] {
    min-width: 0;
    margin-top: 0;
  }
}