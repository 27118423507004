/*** HEADER ***/
#header {
  background: white;
  color: $gray;

  .logo {
    max-width: 100px;
  }

  a:not(.new-btn-primary):not(.new-btn-secondary){
    color: $gray-darker;

    &:hover {
      text-decoration: none;
      color: $brand-primary;
    }
  }

  .header-nav {
    background: $gray-lighter;
    margin-top: 0;
    padding-top: 13px;
    padding-bottom: 14px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

    #menu-icon {
      vertical-align: middle;
      cursor: pointer;
      margin-left: rem(16px);

      .material-icons {
        line-height: 50px;
      }
    }

    .right-nav {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

    .currency-selector {
      margin-top: rem(15px);
      margin-left: rem(15px);
      white-space: nowrap;
    }

    .user-info {
      text-align: right;
      white-space: nowrap;

      .account {
        margin-left: $small-space;
      }

      .header_user_info {
        > a:focus {
          outline: none;
          text-decoration: none;
        }

        a span {
          display: inline-block;
        }
      }
    }

    .language-selector {
      margin-top: rem(15px);
      white-space: nowrap;
    }

    .cart-preview {
      &.active {
        background: $brand-primary;

        a {
          color: white;
        }

        i {
          color: rgba(255, 255, 255, 0.6);
        }
      }

      .shopping-cart {
        vertical-align: middle;
      }

      .body {
        display: none;
      }
    }

    .blockcart {
      background: $gray-light;
      height: rem(48px);
      padding: rem(12px);
      margin-left: rem(15px);
      text-align: center;

      a {
        color: $gray;

        &:hover {
          color: $brand-primary;
        }
      }

      &.active {
        a:hover {
          color: white;
        }
      }

      .header {
        margin-top: rem(2px);
      }
    }

    .material-icons {
      line-height: 11px;
      font-size: 16px;
      width: auto;
      height: auto;
      margin-right: 12px;

      &.expand-more {
        margin-left: rem(-6px);
      }
    }

    .separator {
      display: inline-block;
      margin: auto 23px;
      opacity: 0.5;
    }

    .user-info .separator {
      margin: auto 15px;
    }

    &.not-logged {
      padding-top: 23px;
      padding-bottom: 0;

      #_desktop_contact_link {
        line-height: 34px;
      }
    }
  }

  .header-top {
    background: $crd-primary-color;

    > .container {
      position: relative;
    }

    padding: 25px 0 21px 0;

    .menu {
      > ul {
        > li {
          float: left;
        }
      }

      padding-left: 15px;
      margin-bottom: 0;
    }

    .position-static {
      position: static;
    }

    a[data-depth="0"] {
      color: $white;
      text-transform: uppercase;
    }

    a {
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }

  .blockcart {
    float: right;
    color: white;
    padding: 8px 0 0;

    .cart-products-count {
      background: $crd-secondary-color;
      border-radius: 17px;
      padding: 5px;
      min-width: 28px;
      min-height: 28px;
      display: inline-block;
      text-align: center;
      line-height: 18px;

      #prod-number {
        margin: 0 auto;
        display: block;
      }
    }

    .dropdown-menu {
      box-shadow: 2px 1px 11px 2px rgba(0, 0, 0, .1);
      border-radius: .6rem;
      border: none;
      padding: 0 20px;
      width: 360px;
      right: 15px;
      left: inherit;
      top: 60px;

      > ul {
        max-height: 339px;
        overflow-y: auto;
        margin-top: 19px;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          background-color: #efeeee;
          border-radius: 9px;
          padding-left: 10px;
        }

        &::-webkit-scrollbar:vertical {
          width: 9px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 9px;
          background-color: $crd-secondary-color;
        }

        li:first-child {
          padding-top: 0;
        }
      }
    }

    .product {
      padding: 21px 0;
      font-size: rem(13px);
      color: #454545;
      border-bottom: 1px solid #ececec;

      a {
        color: #454545;
      }

      .product-image {
        margin-right: 10px;
      }

      img {
        max-width: 100%;
        max-height: 77px;
      }

      .product-price {
        font-weight: bold;
        font-size: rem(13px);
        color: $brand-primary;
        margin-top: 8px;
      }

      .prod-name {
        max-height: 49px;
        overflow: hidden;
        padding-right: 10px;
        line-height: 22px;
      }

      .remove-from-cart {
        margin-top: 16px;
        display: block;

        &:focus {
          outline: none;
        }
      }
    }

    .cart-total {
      font-size: rem(13px);

      .label {
        color: black;
        text-transform: uppercase;
        font-weight: bold;
      }

      .value {
        font-weight: bold;
        color: $brand-primary;
      }
    }

    .to-checkout {
      margin: 25px 0;
    }

    .btn-primary {
      width: 100%;
    }

    .no-btn {
      .value {
        display: inline-block;
        margin: 0 10px;
        position: relative;
      }
    }

    .display-mobile {
      display: none;
    }
  }

  .nav-top {
    box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.11);
    position: relative;
    z-index: 5;
  }

  .top-menu-link {
    margin-left: $medium-space;
  }

  .material-icons.shopping-cart {
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
  }

  #login-form {
    .input-group {
      .btn {
        line-height: 13px;
      }
    }
  }

  #header-login-content {
    .btn.show-password-btn {
      color: inherit;
      width: inherit;
    }
  }

  #header-login {
    label {
      display: none;
    }

    .form-group {
      margin-bottom: 0;
      float: left;

      .form-control {
        border-radius: 17px;
        width: 193px;
        padding: 7px 14px 7px 13px;
      }
    }

    .form-control-comment {
      padding: 0;
    }

    .form-footer {
      float: left;
    }

    .pull-right-header {
      float: right;

      section {
        float: left;
      }
    }

    .btn-primary {
      line-height: 35px !important;
      height: 34px;
    }

    .form-group .show-psw {
      margin: 0 13px;

      input {
        padding: 7px 27px 7px 13px;
      }
    }

    #create-account:not(.new-btn-secondary) {
      margin-left: 8px;
      color: white;

      &:hover {
        color: white;
      }
    }

    .forgot-password {
      a {
        color: $gray;
      }
    }
  }
}

.popover {
  font-family: inherit;
}

#cookieNotice {
  background: white;
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  font-size: 14px;
  line-height: 1.3em;
  left: 0px;
  text-align: center;
  color: $brand-primary;
  opacity: 50;
  padding: 10px 0;
  border: 1px solid #ccc;

  a {
    float: left;
  }

  h3 {
    padding-top: 12px;
  }
}

/*** WRAPPER ***/
#wrapper {
  background: white;
  //box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.11);
  /*box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);*/
  padding-top: 38px;

  .banner {
    margin-bottom: 24px;
    display: block;

    img {
      box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .breadcrumb {
    background: transparent;
    margin-bottom: rem(49px);
    border-bottom: 1px solid $gray-light;
    padding: rem(15px) 0 rem(20px);
    font-size: 12px;
    color: $gray;

    &[data-depth="1"] {
      display: none;
    }

    ol {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      display: inline;

      &::after {
        content: "/";
        color: $gray-dark;
        margin: 0 8px;
      }

      &:last-child {
        content: "/";
        color: $gray-dark;
        margin: rem(5px);

        &::after {
          content: "";
        }

        a {
          color: $brand-primary;
        }
      }

      a {
        color: $gray;
      }
    }
  }
}

/*** MAIN ***/
#main {
  .page-header {
    margin-bottom: rem(25px);
  }

  .page-content {
    margin-bottom: rem(25px);

    h6 {
      margin-bottom: rem(18px);
    }

    #notifications {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .page-footer {
    margin-bottom: rem(25px);
  }
}

#notifications {
  ul {
    margin-bottom: 0;
  }
}

.left-column-title {
  @include special-border;
  position: relative;
  color: #222;
}

.page-header h1 {
  @include special-border;
  position: relative;
}

/** MAIN PAGE **/
#index #wrapper {
  padding-top: 0px;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.js-product-list-selection {
  .inline-display {
    display: inline-block;
  }

  .select-title {
    border: 1px solid #e6e6e6;
    border-radius: 14px;
    color: #8b8b8b;
    line-height: 28px;
    padding: 0px 8px 0 13px;
    font-size: 12px;

    .material-icons {
      line-height: 28px;
      margin: 2px 0 2px -4px;
      font-size: 16px;
    }
  }

  .pagination {
    .page-list {
      padding: 0;
    }

    li {
      text-align: center;

      a {
        border-radius: 50%;
        border: 1px solid #e6e6e6;
        color: #8b8b8b;
        font-weight: normal;
        display: inline-block;
        min-width: 25px;
        height: 25px;
        line-height: 20px;
        font-size: 12px;
      }

      &.current {
        a {
          color: #fff;
          background: $btn-primary-bg;
        }
      }
    }

    .material-icons {
      font-size: 14px;
      margin-top: -2px;
    }

    .next .material-icons {
      margin-left: 2px;
    }
  }
}

.clr-primary {
  color: $crd-primary-color;
}

#powered {
  display: none;
}

#partners-logo-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 60px 0 50px;
  margin-top: 60px;
  border-top: 1px solid $input-border;

  .partner-logo {
    width: 180px;
    text-align: center;
  }
}

//******************** Events page **********************
.page-header .events-header {
  text-align: center;
  margin-top: 56px;
  font: 300 30px $mainFontFamily-light;
  color: $crd-primary-color;
  padding-bottom: 18px;
  margin-bottom: 24px;

  &:after {
    background: $input-border;
    width: 422px;
    max-width: 100%;
    bottom: 0;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.page_description {
  font: 300 16px $mainFontFamily-light;
  color: $crd-primary-color;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}

.events-content {
  #accordion {
    .panel-heading {
      &:after {
        content: none;
      }

      a {
        line-height: inherit;
        font: 700 15px $mainFontFamily-bold;
        color: $crd-primary-color;

        &:before {
          top: 59%;
        }

        &:focus {
          outline: none;
          text-decoration: none;
        }
      }
    }

    .panel-heading {
      .panel-title {
        padding: 10px 0;
        margin-top: 10px;
      }
    }
  }

  .events-info {
    float: left;
  }

  .event-info {
    padding: 0 0 15px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .event-cont {
    padding: 23px 21px;
    border-bottom: 1px solid $gray-light2;
    display: flex;
    align-items: center;
  }

  .day {
    font: 700 35px $mainFontFamily-bold;
    color: $crd-primary-color;
    width: 68px;
    float: left;
    word-wrap: break-word;
    line-height: 100%;
    margin-right: 16px;
  }

  .name {
    font: 700 16px $mainFontFamily-bold;
    color: $cms-text;
    padding: 0 0 4px;
  }

  .info {
    font: 300 14px $mainFontFamily-light;
    color: $cms-text;
  }

  .date {
    float: left;
    border-right: 1px solid $cms-text;
    padding-right: 5px;
    margin-right: 5px;
  }

  .location {
    float: left;
  }
}

/*-------------------------*/
/* Absolute Center Spinner */
#shippingMethodLoader {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
#shippingMethodLoader:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
#shippingMethodLoader:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

#shippingMethodLoader:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

.full-width-home-banner {
  &.middle {
    box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, .11);
  }

  img {
    max-width: 100%;
    width: 100%;
  }
}

#custom-text {
  h3.black-font {
    color: $prod-title;
  }
}

#index #footer {
  padding-top: 0;
}

#erp-live-alert {
  background: red;
  color: white;
  text-align: center;
  font-size: 16px;
  padding: 7px 0;
}

.connection-err-img {
  max-width: 100%;
  display: block;
}

.alert-danger.erp-alert {
  p {
    color: #a94442;
  }

  h4 {
    font-size: 18px;
  }
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------*/

/*** Responsive part ***/
@include media-breakpoint-down(md) {
  #header {
    #header-login {
      &.not-logged {
        .header_user_info {
          display: none;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #_desktop_cart {
    padding: 0;
    padding-right: 15px;
  }
  #header {
    #header-login {
      .form-group .form-control {
        width: 206px;
      }
    }
  }
  #cookieNotice {
    z-index: 2147483640;
    padding-right: 15px;
  }
  #checkoutBillingAddress {
    .cart-label-subtitle {
      margin-right: -26px;
    }
  }
  .w-100-mobile {
    width: 100%;
  }
}

@include media-breakpoint-down(sm) {
  #wrapper {
    box-shadow: none;
    padding-top: rem(25px);
  }
  #checkout-cart-summary {
    float: none;
    width: 100%;
    margin-top: 1rem;
  }
  #header {
    background: $gray-lighter;

    .header-nav {
      background: $brand-primary;
      margin-bottom: 0;
      margin-top: 0;
      color: white;
      max-height: none;
      box-shadow: none;

      .top-logo {
        line-height: 50px;
        vertical-align: middle;
        width: 200px;
        margin: 0 auto;
        padding: 0 0 15px;

        a {
          img {
            max-height: 50px;
            max-width: 100%;
          }
        }
      }

      .right-nav {
        flex-direction: column;
      }

      .user-info {
        text-align: left;
        margin-left: 0;
      }

      .logged {
        color: $brand-primary;

        .user-info {
          .account {
            margin: 0;
            display: block;
          }

          .material-icons {
            margin: 0;
          }
        }
      }

      .blockcart {
        margin-left: 0;
        background: inherit;

        &.active {
          margin-left: rem(8px);
        }

        &.inactive {
          .cart-products-count {
            display: none;
          }
        }

        .shopping-cart {
          margin: 0;
        }

        .value {
          margin: 0 10px;
        }

        a {
          color: white;

          &:hover {
            color: white;
          }
        }
      }

      .material-icons {
        font-size: rem(24px);
        line-height: inherit;
      }

      .cart-preview.active i {
        color: white;
        line-height: rem(21px);
      }

      #_mobile_cart {
        .cart-products-count {
          background: $crd-secondary-color;
          border-radius: 17px;
          padding: 5px;
          min-width: 28px;
          min-height: 28px;
          display: inline-block;
          text-align: center;
          line-height: 18px;
        }

        .display-mobile {
          display: block;
        }

        #dropdownMenuButton {
          display: none;
        }
      }
    }

    .header-top {
      background: $gray-light;
      padding: 10px 0;
      background: $brand-primary;

      a[data-depth="0"] {
        color: $gray-darker;
      }
    }

    #_mobile_user_info {
      &.logged {
        position: absolute;
        right: 45px;
        top: 16px;
      }

      &.not-logged {
        background: #fff;

        .form-group {
          width: 100%;
          margin: 5px 0 0;

          .show-psw {
            margin: 0;
          }

          .form-control {
            width: 100%;
          }
        }

        #login-form {
          padding: 10px 15px 0;

          section {
            width: calc(100% - 120px);
            padding-right: 6px;
          }
        }

        .form-footer {
          width: 120px;
          margin-top: 5px;
        }

        .btn {
          width: 119px;
          display: block;
          font-size: 12px;
        }

        #create-account {
          margin: 5px 0 0;
        }

        .pull-right-header {
          float: none;
        }

        .forgot-password {
          line-height: initial;
          text-align: right;
          margin-right: 12px;
          margin-bottom: 5px;
        }
      }
    }

    #header-login {
      .btn {
        width: 144px;
      }
    }
  }
  section.checkout-step {
    width: 100%;
  }
  .default-input {
    min-width: 100%;
  }
  label {
    clear: both;
  }
  
  .block-contact {
    padding-left: 0.9375rem;
    border: none;
  }
  .menu,
  .dropdown-item {
    padding-left: 0;
  }
  #header-login-content li ul li {
    padding: 0 10px;

    &.active {
      a {
        color: white;
      }
    }
  }
  .js-product-list-selection {
    .select-title {
      padding: 0 3px 0 13px;

      .material-icons {
        margin: 2px 0 2px 2px;
      }
    }
  }
  #cookieNotice {
    padding-right: 0;

    a {
      margin: 0 0 10px;
      float: none;
    }
  }
  #p_slider {
    width: 90%;
    margin: 0 auto;
  }
}

@include media-breakpoint-down(md) {
  .container {
    max-width: 100%;
  }
  #header .logo {
    width: auto;
  }

  .sub-menu {
    left: 0;
    min-width: 100%;
  }
  #blockcart-modal .product-image {
    width: 100%;
    display: block;
    max-width: 250px;
    margin: 0 auto rem(15px);
  }
  #blockcart-modal .cart-content {
    padding-left: 0;
  }
  #blockcart-modal .product-name,
  #product-availability {
    margin-top: $small-space;
  }
  #search_filters .facet .facet-label {
    text-align: left;
  }
  .block-category .category-cover {
    position: relative;
    text-align: center;
  }
  .block-category {
    padding-bottom: 0;
  }
  #header .header-nav .user-info .header_user_info a span {
    max-width: 247px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #checkoutBillingAddress {
    .cart-label-subtitle {
      margin-right: 0;
    }
  }
  .thumbnail-container .btn-primary {
    font-size: 11px;
  }
}

@media (min-width: 360px) {
  #wrapper {
    min-height: calc(100vh - 285px);
  }
}

@media (min-width: 768px) {
  #wrapper {
    min-height: calc(100vh - 485px);
  }
}

@media (min-width: 1200px) {
  .col-xl-5th {
    width: 20%;
    float: left;
  }
}

@media(max-width: 500px) {
  .text-xs-center {
    text-align: center;
  }
}

.h1, h1 {
  font-size: 1.875rem;
}

.h2, h2 {
  font-size: 1.5rem;
}

.h5, .h6, h5, h6 {
  font-size: .8125rem;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 700;
}

a {
  color: $brand-primary;
  text-decoration: none;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: none;
}

.input-group {
  display: table;
}

.btn-secondary, .btn-tertiary {
  color: white;
  background-color: #ddd;
  border-color: transparent;
}

.row.space-between {
  justify-content: space-between;
}

.carousel-control-prev, .carousel-control-next {
  width: 10%;
}

.center {
  text-align: center;
}

#supercheckoutLoader {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
#supercheckoutLoader:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

#supercheckoutLoader:after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}
