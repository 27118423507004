.lang-rtl {
  * {
    direction: rtl !important;
  }

  main {
    text-align: right !important;
  }
}

body,
html {
  height: 100%;
}

body {
  font-family: $mainFontFamily, sans-serif;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $gray-darker;
  line-height: 1.25em;
}

ul {
  list-style: none;
  padding-left: 0;
}

a:hover {
  color: $brand-primary;
  text-decoration: none;
}

p {
  font-size: rem(13px);
  color: $gray-dark;
  font-weight: 400;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: none;
}

.color,
.custom-checkbox input[type="checkbox"] + span.color {
  width: rem(20px);
  height: rem(20px);
  display: inline-block;
  margin: rem(5px);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background-size: contain;

  &.active,
  &:hover {
    border: 2px solid $gray-darker;
  }
}

.facet-label {
  &.active,
  &:hover {
    .custom-checkbox span.color {
      border: 2px solid $gray-darker;
    }
  }
}

h1, .h1 {
  font-size: rem(30px);
}

.h1,
.h2,
.h3 {
  text-transform: uppercase;
  color: $gray-darker;
}

.h4 {
  font-weight: $headings-font-weight;
  color: $gray-darker;
}

.btn-primary,
.btn-secondary {
  @include box-shadow;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  line-height: 36px;
  padding: 0 15px;
  font-family: $headingFontFamily, sans-serif;

  .material-icons {
    margin-right: $small-space;
  }
}

.btn-tertiary {
  @extend .btn-secondary;
  text-transform: lowercase;
  color: $gray;
  box-shadow: rem(1px) rem(1px) rem(1px) 0 rgba(0, 0, 0, 0.1);
  padding: rem(4px);
  margin: rem(4px) 0;
  font-weight: 400;
  font-size: $font-size-sm;

  .material-icons {
    font-size: $base-font-size;
  }
}

.card {
  @include box-shadow;
}

label, .label {
  color: $gray-darker;
  text-align: right;
  font-size: rem(14px);
}

small.label, small.value {
  font-size: rem(13px);
}

.form-control-label {
  padding-top: rem(10px);
}

.form-control {
  background: $gray-light;
  color: $gray;
  border: none;
  padding: rem(8px) rem(16px);

  &:focus {
    background-color: white;
    outline: rem(3px) solid $brand-primary;
  }
}

.input-group {
  &.focus {
    outline: rem(3px) solid $brand-primary;
  }

  .form-control:focus {
    outline: none;
  }

  .input-group-btn > .btn {
    border: 0;
    box-shadow: none;
    color: #ffffff;
    font-size: rem(11px);
    font-weight: normal;
    margin-left: 0;
    padding: rem(10.5px) rem(16px);
    text-transform: uppercase;
  }
}

.form-control-select {
  height: rem(42px);
  /* -moz-appearance: none;
   -webkit-appearance: none;
   background: $gray-light url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=") no-repeat scroll right rem(8px) center / rem(20px) rem(20px);*/
  padding-right: rem(32px);
}

.form-control-valign {
  padding-top: rem(8px);
}

.form-control-comment {
  font-size: rem(14px);
  padding-top: rem(8px);
  color: $gray-dark;
}

.form-control-submit {
  &.disabled {
    background: $brand-info;
    color: white;
  }
}

.form-group {
  &.has-error {
    input,
    select {
      outline: rem(3px) solid $brand-danger;
    }

    .help-block {
      color: $brand-danger;
    }
  }
}

.group-span-filestyle {
  label {
    margin: 0;
  }

  .btn-default {
    background: $brand-primary;
    color: white;
    text-transform: uppercase;
    border-radius: 0;
    font-size: rem(14px);
    padding: rem(8px) rem(16px);
  }
}

.bootstrap-touchspin {
  input {
    &:focus {
      outline: none;
    }

    &.form-control {
      border: $input-btn-border-width solid $input-border-color;
    }
  }

  .btn-touchspin {
    @extend .btn-default;
    background-color: white;
    border: $input-btn-border-width solid $input-border-color;
    height: ($input-height / 2) + rem($input-btn-border-width);

    &:hover {
      background-color: $gray-light;
    }
  }

  .input-group-btn-vertical {
    color: $gray-darker;

    .bootstrap-touchspin-up {
      border-radius: 0;
    }

    .bootstrap-touchspin-down {
      border-radius: 0;
    }

    .touchspin-up {
      &:after {
        content: "\E5CE";
      }
    }

    .touchspin-down {
      &:after {
        content: "\E5CF";
      }
    }

    i {
      top: rem(1px);
      left: rem(3px);
      font-size: rem(15px);
    }
  }
}

.custom-radio {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: $gray 2px solid;
  background: white;
  margin-right: rem(20px);

  input[type="radio"] {
    opacity: 0;
    cursor: pointer;
  }

  input[type="radio"]:checked + span {
    display: block;
    background-color: $brand-primary;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    left: rem(2px);
    top: rem(2px);
  }
}

.custom-checkbox {
  position: relative;

  input[type="checkbox"] {
    margin-top: rem(4px);
    opacity: 0;
    cursor: pointer;
    position: absolute;

    + span {
      margin-right: 3px;
      display: inline-block;
      width: rem(15px);
      height: rem(15px);
      vertical-align: middle;
      cursor: pointer;
      border: 2px $gray-darker solid;

      .checkbox-checked {
        display: none;
        margin: rem(-4px) rem(-2px);
        font-size: 1.1rem;
        color: $gray-darker;
      }
    }

    &:checked + span {
      .checkbox-checked {
        display: block;
      }
    }
  }

  label {
    text-align: left;
  }
}

.text-muted {
  font-size: rem(14px);
}

.done {
  color: $brand-success;
  display: inline-block;
  padding: 0 rem(13px);
  margin-right: rem(25px);
}

.thumb-mask {
  > .mask {
    position: relative;
    width: rem(55px);
    height: rem(55px);
    overflow: hidden;
    border: $gray-light 1px solid;
    margin: $small-space 0;

    img {
      width: 55px;
      height: 55px;
    }
  }
}

.definition-list {
  dl {
    display: flex;
    flex-wrap: wrap;

    dt {
      font-weight: normal;
    }

    dd,
    dt {
      flex: 0 0 45%;
      background: $gray-light;
      padding: rem(10px);
      margin: rem(2px);

      &:nth-of-type(even) {
        background: $gray-lighter;
      }
    }
  }
}

.help-block {
  margin-top: $small-space;
}

.btn.disabled,
.btn.disabled:hover {
  background: $gray;
}

.alert-warning {
  .material-icons {
    color: $warning;
    font-size: rem(32px);
    margin-right: $small-space;
    padding-top: $extra-small-space;
  }

  .alert-text {
    font-size: rem(15px);
    padding-top: $small-space;
  }

  .alert-link {
    border-radius: 2px;
    border-width: 2px;
    margin-left: $small-space;
    padding: $extra-small-space $medium-space;
    font-weight: 600;
    font-size: rem(13px);
    color: $btn-tertiary-color;
  }

  ul li:last-child .alert-link {
    color: white;
  }

  .warning-buttons {
    margin-top: $extra-small-space;
  }
}

.update-box{
  button.cart-button{
    box-shadow: none;
  }
}

.btn-warning {
  @include transition(all .4s ease-in-out);
}

.btn-tertiary-outline {
  color: $btn-tertiary-color;
  background-image: none;
  background-color: transparent;
  border-color: $btn-tertiary-color;
  border: 0.15rem solid $btn-tertiary-color;
  @include transition(all .4s ease-in-out);

  &:hover {
    border-color: $btn-tertiary-hover;
    color: $btn-tertiary-hover;
  }
}

.alert {
  font-size: rem(13px);
}

.nav-item {
  .nav-link,
  .nav-separtor {
    color: $gray;
    font-weight: bold;

    &.active {
      color: $gray-darker;
    }
  }
}

.hidden {
  display: none;
}

.no-btn {
  border: none;
  background: none;
  color: inherit;

  &:focus {
    outline: none;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.gray-background {
  background: $white-smoke;
}

.btn-primary:focus,
.btn:active:focus {
  outline: none;
}

.bold {
  font-weight: bold;
}

.rounded-border {
  border-radius: 10px;
  border: 1px solid $gray-light;
}

.btn-primary .fa-eye {
  font-size: 15px;
}

#product .available-status.green {
  color: #4cb349
}

#product .available-status.warning {
  color: #f0ad4e
}

#product .available-status.red {
  color: #ff0f0f;
}

.pad-r-15 {
  padding-right: 15px;
}

.pad-l-15 {
  padding-left: 15px;
}

.relative {
  position: relative;
}

#return-to-top {
  display: inline-block;
  background-color: $crd-secondary-color;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color .3s,
  opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;

  &::after {
    content: "\f077";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 2em;
    line-height: 50px;
    color: #fff;
  }

  &:hover {
    cursor: pointer;
    background-color: $crd-primary-color;
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  &:active {
    background-color: $crd-primary-color;
    text-decoration: none;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.m-neg-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.m-l-r-15,
.m-l-r-15.no-gutters {
  margin-left: 15px;
  margin-right: 15px;
}

.modal-header {
  display: block;
}

.connection-err-img {
  max-width: 100%;
  display: block;
}

#amazzing_filter {
  .af_filter {
    .toggle-cut-off{
      position: initial;
      margin-bottom: 10px;
    }
  }
}