#myModal {
  &.modal {
    padding-top: 100px;
  }

  .modal-dialog .modal-header {
    h3 {
      display: inline-block;
    }
    a {
      display: inline-block;
      margin: 0 5px 5px 5px;
    }
    button.close {
      padding-top: 0;
    }
  }

  .modal-body {
    height: calc(100vh - 300px);
    max-height: 650px;
    overflow-y: auto;
  }

  .products-flex {
    .product-block {
      margin-bottom: 20px;
    }
    @media (min-width: 1081px) {
      @include flexboxGridMixin(3, 40, 40);
    }
    @media (max-width: 1080px) and (min-width: 769px) {
      @include flexboxGridMixin(3, 20, 20);
    }
    @media (max-width: 768px) and (min-width: 601px)  {
      @include flexboxGridMixin(2, 20, 20);
    }
    @media (max-width: 600px) {
      @include flexboxGridMixin(1, 0, 20);
    }
    .product-miniature .price-cart-wrapper .product-price-and-shipping.empty {
      flex: 1 0 45%;
    }
    .product-miniature .price-cart-wrapper .product-price-and-shipping.empty+.add-cart-redesign {
      flex: 1 0 calc(55% - 21px);
    }
  }
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url(../img/fancybox_loading.gif) center no-repeat;
}

.supercheckout-blocks .cart-item .cart_quantity_down,
.supercheckout-blocks .cart-item .cart_quantity_up {
  display: none;
}

.info-assortment, .info-discount {
  cursor: pointer;
}

#graduationNotice,
#boxQtyNotice {
  background: none;
  display: none;
  width: 100%;
  position: fixed;
  top: 10px;
  z-index: 9999;
  font-size: 14px;
  line-height: 1;
  left: 0px;
  text-align: center;
  color: #fff;
  opacity: 1;
  .close {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7.5px;
    background: url('../img/icons/close-menu-white.svg') no-repeat;
    content: '';
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
    z-index: 1;
    opacity: 1;
  }
  .row {
    .info {
      position: relative;
      padding: 10px 0;
      border-radius: 5px;
      min-height: 20px;
      opacity: 1;
      margin-bottom: 10px;
      &:before {
        content: " ";
        background: url('../img/icons/info-assort-icon-white.svg') no-repeat;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
        position: absolute;
        z-index: 1;
        top: 50%;
        margin-top: -10px;
        left: 10px;
      }
    }

    span {
      line-height: 1.2;
      padding: 0 35px;
      display: inline-block;
      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }

    span.current-qty-free,
    span.next-qty {
      display: block;
    }

    span.separator {
      margin: 5px 0;
      width: 50%;
      height: 1px;
      background: white;
      display: inline-block;
      overflow: hidden;
    }
  }
}

#graduationNotice .info {
  background: #ff4c4c;
}
#header #multiAdd {
  color: #fff;
  float: right;
  margin-right: 10px;
}
@media (max-width: 1025px) {
  #graduationNotice,
  #boxQtyNotice {
    width: calc(100% - 30px);
    margin: 0 15px;
  }
  #boxQtyNotice {
    top: 85px;
  }
  #myModal.modal {
    padding-top: 10px;
  .modal-body {
    height: calc(100vh - 160px);
    }
  }
  .modal-dialog .modal-header button.close{
    margin-top: -16px!important;
    margin-right: -25px;
  }
}

@media (max-width: 450px) {
  #boxQtyNotice {
    .row {
      span {
        font-size: 13px;
      }
    }
  }
}
