#password {
  #main {
    background: #f6f6f6;
    width: 521px;
    margin: 100px auto;

    #image-holder {
      height: 48px;
      width: 48px;
      background: url("../img/icons/mail-icon.svg");
    }

    .lock-icon {
      background: url("../img/icons/lock-icon.svg");
      width: 12px;
      height: 15px;
      display: inline-block;
      margin-right: 15px;
    }

    .recovery-title {
      margin-top: 10px;
      font-size: 24px;
      font-weight: normal;
      line-height: 36px
    }

    label {
      display: block;
      font-size: 12px;
      line-height: 18px;
      color: #A0A0A0;
      float: left;

      &:before {
        content: '';
      }
    }

    #send-reset-password-link {
      margin: 50px auto;
      display: block;
      padding: 12px 30px 16px 30px;
      font-size: 20px;
      line-height: 23px !important;
      height: auto;
      text-transform: none;
      font-weight: normal;
      border-radius: 40px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      box-shadow: 0 2px 4px rgba(30, 59, 90, 0.1);
      background: #2A527E;
    }
  }

  @media(max-width: 767px) {
    #wrapper {
      background: #f6f6f6;

      #main {
        margin: 50px auto;
        width: 100%;

        #image-holder {
          margin: 0 auto;
        }

        .recovery-title, p {
          text-align: center;
        }

        #send-reset-password-link {
          font-size: 14px;
          line-height: 16px !important;
          padding: 10px 20px 14px 20px;
          margin-top: 100px;

          i {
            vertical-align: bottom;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
