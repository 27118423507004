@import "../external/inc/variables";
@import "../external/inc/arrows";

.page-my-account #content .links {
  .circular-arrow-icon, .heart-icon {
    width: 42px;
    height: 42px;
    margin: 0 auto;
    background-size: contain !important;
  }

  .circular-arrow-icon {
    background: url('../img/icons/circular-arrows.svg') no-repeat left center;
  }

  .heart-icon {
    background: url('../img/icons/blue-heart-icon.svg') no-repeat left center;
  }
}

#module-crd_favorites-productlist,
#module-crd_history-historyproducts,
#module-crd_outstandinginvoice-outstanding,
.page-customer-account {
  #main {
    background: #fff;
    padding: 0 30px;
    border-radius: 5px;
    &::after {
      content: '';
      display: table;
      clear: both;
    }
    @media (max-width: 767px) {
      border-radius: 0;
    }

    .crd-history .js-product-list-selection,
    .js-product-list-selection {
      float: none;
      padding: 20px 0;
      margin: 0;
    }
    .page-content.page-not-found {
      display: inline-block;
    }
  }
}

@media (min-width: 811px) {
  .page-customer-account,
  #module-crd_orderhistory-orderdetails,
  #module-crd_history-historyproducts,
  #module-crd_outstandinginvoice-outstanding,
  #module-crd_favorites-productlist {
    #left-column.col-md-3 {
      min-width: 240px;
      max-width: 240px;
    }

    #content-wrapper.col-md-9 {
      max-width: calc(100% - 240px);
    }

    #left-column {
      h2 {
        padding: 15px 0 0 10px;
        margin: 0 0 20px 0;
        font-size: 12px;
        font-weight: bold;
        font-family: GalaxiePolarisBook;

        a {
          color: $gray;
        }

        &:after {
          display: none;
        }
      }
    }
  }
}

.account-left-menu {
  li {
    padding: 0;

    a {
      display: inline-block;
      width: 100%;
      font-size: 12px;
      font-family: NeoSansProMedium, sans-serif;
      color: $crd-primary-color;
      font-weight: 500;
      padding: 0 10px;
      line-height: 30px;

      &.active {
        color: $gray;
        background: $background-blue;
        border-radius: 4px;
      }

      &:before {
        content: '';
        display: none;
      }
    }
  }
}

.my-account-buttons {
  margin-left: 35px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    padding: 20px 30px 20px 50px;
    margin: 0;
    border-bottom: 1px solid $border-gray;
  }
  @media (min-width: 768px) {
    padding: 0 0 20px 0;
  }

  .back-btn {
    color: #2A527E;
    border: none;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 14px;
    }

    &:hover {
      text-decoration: underline;
    }

    &:before {
      @include arrow;
      border-right: none;
      border-bottom: none;
      @include vendor-prefix("transform", rotate(180deg));
      top: 4px;
      margin-bottom: auto;
      left: -20px;
    }
  }

  .reorder-btn:not(.new-btn-primary) {
    font-size: 12px;
    margin: 0;
    text-transform: none;
    height: 30px;
    line-height: 29px !important;
  }
}

.page-customer-account,
#module-crd_history-historyproducts,
#module-crd_outstandinginvoice-outstanding,
#module-crd_favorites-productlist {
  #main {
    .page-header {
      margin: 0;

      h1 {
        font-size: 18px;
        line-height: 27px;
        margin: 0;
        padding: 15px 0;
        font-weight: bold;
        @media (max-width: 767px) {

        }
      }
    }
  }

  #order-history-erp {
    .order-block {
      border: 1px solid #d1d1d1;
      border-radius: 8px;
      padding: 15px 10px;
      margin-bottom: 10px;
      color: $dark-gray;
      font-weight: bold;

      label {
        color: $light-gray;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .display-flex {
        display: flex;
        align-content: center;
        justify-content: space-between;
      }

      .order-block-header {
        margin-bottom: 15px;
      }

      a {
        text-transform: none;
      }
    }
  }

  .order-history-description {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 24px;
    }
  }
}

#module-crd_orderhistory-orderdetails, #order-confirmation {
  .order-product-block {
    padding: 10px 30px;
    border-bottom: 1px solid $border-gray;
    @media (min-width: 768px) {
      padding: 10px 0;
    }
  }

  .display-flex {
    display: flex;
    align-content: center;
    justify-content: space-between;
    color: $dark-gray;
    font-weight: bold;
    margin-bottom: 5px;
  }

  label {
    color: $light-gray;
    font-size: 12px;
    font-weight: bold;
    text-transform: none;
  }

  .order-totals-footer {
    padding: 10px 0 0 0;

    .display-flex > div:nth-child(2) {
      text-align: right;
      color: $crd-primary-color;
      font-weight: bold;
    }

    .display-flex {
      font-weight: normal;
      font-size: 13px;
      padding: 10px 30px;
      margin-bottom: 0;
      line-height: 20px;
      @media (min-width: 768px) {
        padding: 10px 0;
      }

      &.line-total {
        padding: 20px 30px;
        font-weight: bold;
        background: #e9e9e9;
        @media (min-width: 768px) {
          padding: 10px 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .page-customer-account,
  #module-crd_orderhistory-orderdetails,
  #module-crd_outstandinginvoice-outstanding,
  #module-crd_favorites-productlist,
  #module-crd_history-historyproducts {
    main {
      background: #fff;
    }

    #main {
      padding: 0 15px;
    }

    #wrapper {
      padding: 0;
      background: #fff;

      > .container {
        padding-left: 0;
        padding-right: 0;
      }
    }

    #order-details-page {
      .detail-wrapper {
        padding: 0;
        border-radius: 0;

        .page-header {
          padding: 15px 30px;
          margin: 0;
          border-bottom: 1px solid $border-gray;
        }
      }
    }

    #footer {
      margin-top: 0;
      padding-top: 0;
    }

    #left-column {
      h2 {
        margin: 10px 0 0 10px;
      }
    }

    .account-left-menu {
      li {
        line-height: inherit;
        margin-bottom: 10px;

        a {
          line-height: 20px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .page-my-account {
    #content {
      .row.w-100 {
        width: calc(100% + 30px) !important;
      }
    }
  }
  .page-customer-account #left-column.col-md-3 {
    display: none;
  }

  #content-wrapper.col-md-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mm-list > li.my-account a.mm-subopen {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  #order-list.table {
    display: block;
    overflow: auto;
    border: none;
  }
}
