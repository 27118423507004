#products {
  color: #222;

  .products-select {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .up {
    margin-bottom: torem(16px);

    .btn-secondary {
      color: $gray;
      text-transform: inherit;

      .material-icons {
        margin-right: 0;
      }
    }
  }

  .searched-for {
    color: $gray-dark;
    margin: 10px 0 17px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebebeb;
  }

  #search-string {
    color: $crd-secondary-color;
  }
}

.block-category {
  min-height: inherit;
  margin-bottom: 58px;

  &.block-category-cart {
    border-radius: 7px;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.21);
    background: #fff;
    height: 227px;
    padding: 19px 0 29px 0;
    box-sizing: border-box;
    border: 1px solid #dadada;

    i.fa-trash-o {
      color: #949494;
      font-size: 22px;
      margin-top: 6px;
    }
  }

  #category-description {
    p,
    strong {
      font-weight: 400;
      color: $gray;
    }

    p {
      color: $gray-darker;
      margin-bottom: 0;

      &:first-child {
        margin-bottom: $medium-space;
      }
    }
  }

  .category-cover {
    //position: absolute;
    //right: 0.75rem;
    //bottom: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.products-selection {
  .sort-by-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sort-by {
    white-space: normal;
    word-break: break-word;
    margin-right: torem(-15px);
    margin-left: torem(15px);
    text-align: right
  }

  .total-products {
    padding-top: torem(10px);
  }

  h1 {
    padding-top: torem(10px);
  }
}

.products-sort-order {
  color: $gray-dark;

  .select-title {
    display: inline-block;
    margin-left: torem(6px);
    width: 100%;
    color: $gray-darker;
    background: white;
    padding: $small-space;
    cursor: pointer;
  }

  .select-list {
    display: block;
    color: $gray-darker;
    padding: $small-space $medium-space;

    &:hover {
      background: $brand-primary;
      color: white;
      text-decoration: none;
    }
  }

  .dropdown-menu {
    left: auto;
    width: torem(270px);
    background: $gray-lighter;
    border: none;
    border-radius: 0;
    right: 0;
  }
}

#search_filters {
  margin-bottom: torem(25px);
  padding: 0;

  .facet {
    .collapse {
      display: block;
    }

    padding-top: $small-space;

    .facet-title {
      color: $gray;
    }

    .facet-label {
      margin-bottom: 0;

      a {
        margin-top: torem(7px);
        color: $gray-darker;
        display: inline-block;
        font-size: $font-size-lg;
      }
    }
  }
}

.pagination {
  width: 100%;
  margin: 0;

  > div:first-child {
    line-height: torem(40px);
  }

  .page-list {
    background: white;
    padding: 0;
    margin-bottom: 0;

    li {
      display: inline-block;
    }
  }

  a {
    color: black;
    font-weight: 600;
  }

  .previous {
    float: none;
  }

  .next {
    float: none;
  }

  .disabled {
    color: $gray;
  }

  .current a {
    color: $brand-primary;
    text-decoration: none;
  }

  .previous.disabled, .next.disabled {
    display: none;
  }
}

.active_filters {
  background: #dededd;
  padding: $small-space $large-space 0;
  margin-bottom: $medium-space;

  .active-filter-title {
    display: inline;
    margin-right: $small-space;
    font-weight: 600;
  }

  ul {
    display: inline;
  }

  .filter-block {
    @include box-shadow;
    color: $gray-darker;
    margin-right: $small-space;
    margin-bottom: $small-space;
    background: white;
    padding: torem(10px);
    display: inline-block;
    font-size: $font-size-xs;

    .close {
      color: $gray-darker;
      font-size: $font-size-lg;
      opacity: 1;
      margin-top: torem(3px);
      margin-left: torem(5px);
    }
  }
}

.block-categories {
  background: white;
  padding: 0;
  margin-bottom: torem(25px);

  .category-sub-menu {
    margin-top: $small-space;

    .category-sub-link {
      font-size: $font-size-sm;
    }

    li {
      position: relative;

      &[data-depth="1"] {
        margin-bottom: $small-space;
      }
    }

    li[data-depth="0"] > a {
      font-weight: 600;
      border-bottom: $gray-light 2px solid;
      width: 100%;
      display: inline-block;
      margin: 0.3125rem 0 0;
      padding-bottom: torem(3px);
    }

    li:not([data-depth="0"]):not([data-depth="1"]) {
      padding-left: torem(5px);

      &::before {
        content: "-";
        margin-right: torem(5px);
      }
    }
  }

  a {
    color: $gray-darker;
  }

  .collapse-icons {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    cursor: pointer;

    &[aria-expanded="true"] {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .add,
    .remove {
      &:hover {
        color: $brand-primary;
      }
    }

    .remove {
      display: none;
    }
  }

  .arrows {
    .arrow-right,
    .arrow-down {
      font-size: $font-size-sm;
      cursor: pointer;
      margin-left: 2px;

      &:hover {
        color: $brand-primary;
      }
    }

    .arrow-down {
      display: none;
    }

    &[aria-expanded="true"] {
      .arrow-right {
        display: none;
      }

      .arrow-down {
        display: inline-block;
      }
    }
  }
}

.facets-title {
  color: $gray-darker;
}

.products-selection {
  .filter-button {
    .btn-secondary {
      padding: torem(12px) torem(8px) torem(11px);
    }
  }
}

.show_list, .show_grid {
  cursor: pointer;
  opacity: 1.0;
  font-size: 16px !important;
  color: #8b8b8b;
  margin-left: 5px;

  &.active {
    color: #222;
  }
}

.show_list:hover, .show_grid:hover {
  opacity: 0.7;
}

#products .product_show_list {
  width: 100%;
  max-height: 152px;

  .thumbnail-container {
    width: 100% !important;
    box-sizing: border-box;
    padding: 0 19px;
    min-height: 142px;
    max-height: 156px;
    margin-bottom: 10px !important;

    .product-flags .new {
      left: -4px !important;
    }
  }

  .product-thumbnail {
    width: 16%;
    float: left;

    img {
      margin-left: 0 !important;
      max-width: 100%;
      max-height: 132px;
      margin-top: 5px;
    }
  }

  .product-description {
    width: 84%;
    float: left;
    padding: 27px 0 24px 15px !important;
    height: 87px;

    .left-div {
      float: left;
      width: 50%;
      position: relative;

      h1 {
        margin-top: 0;
        padding-right: 18px;
      }
    }

    .right-div {
      width: 40%;
      float: right;
    }

    .qty-box .input-box {
      margin-right: 30px;
    }

    .add-to-fav, .history-icon {
      position: absolute;
      top: 50%;
      right: -54px;
      transform: translateY(-23px); /*product list*/
    }

    .history-icon {
      right: -24px;
    }
  }
}

.add-to-fav {
  position: absolute;
  cursor: pointer;
  display: block;
  color: #d3d2d2;
  right: 24px;
  bottom: 87px;

  .material-icons {
    font-size: 29px;
  }

  &:hover {
    color: $crd-secondary-color;
  }

  &:focus {
    color: #d3d2d2;
  }
}

.category-cart {
  width: calc(100% - 203px);
  overflow-y: auto;
  margin-left: 25px;
  position: relative;

  .table-cart {
    display: flex;
    width: 100%;
  }

  article {
    width: 160px;
    min-width: 160px;
    max-width: 160px;
    height: 179px;
    flex-basis: auto;
    flex-grow: 1;
    vertical-align: middle;
    text-align: center;
    padding: 0 0 10px 0;

    .article-content {
      border-right: 1px solid #c8c8c8;
      padding: 0 25px;
    }

    &:first-of-type {
      width: 135px;
      min-width: 135px;
      max-width: 135px;

      .article-content {
        padding-left: 0;
      }
    }

    &:last-child {
      .article-content {
        border-right: none;
      }
    }

    img {
      margin-left: 0;
      max-height: 75px;
      padding: 5px;
    }

    h3 {
      font-size: 12px;
      line-height: 13px;
      color: $brand-primary-title;
      font-weight: 700;
      height: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
      font-weight: 700;
      font-family: GalaxiePolarisBook, sans-serif;
      color: $brand-primary;
      line-height: 14px;
      text-align: left;
      margin: 0;
    }

    .product-description {
      padding: 10px 0 0 0;

      .qty-box {
        padding: 0;

        .input-box {
          max-width: 75px;

          input.qty-input {
            font-size: 14px;
            height: 34px;
            line-height: 34px;
          }
        }

        .decrease {
          left: 5px;
        }

        .value-button.increase {
          right: 5px;
        }

        .value-button {
          top: 8px;

          .material-icons {
            font-size: 16px;
          }
        }
      }

      .remove-from-cart .material-icons {
        line-height: 34px;
        color: #949494;
      }
    }
  }
}

.category-cart.horizontal {
  img {
    border-radius: 0;
    max-width: 100%;
    width: 75px;
    height: 75px;
  }
}

.category-cart::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: #efeeee;
  border-radius: 9px;
}

.category-cart::-webkit-scrollbar:horizontal {
  height: 18px;
}

.category-cart::-webkit-scrollbar-thumb {
  border-radius: 9px;
  background-color: $crd-secondary-color;
}

.category-cart-summary {
  width: 170px;
  position: absolute;
  top: 0;
  right: 0;
  font-family: GalaxiePolarisBook, sans-serif;

  h2 {
    font-size: 16px;
    font-weight: 700;
    color: $crd-primary-color;
    margin: 0;
    text-transform: uppercase;
    padding-bottom: 10px;

    &:after {
      content: "";
      background: #e6e6e6;
      position: absolute;
      top: 25px;
      left: 0;
      height: 1px;
      width: 50px;
    }
  }

  .total-articles {
    font-size: 12px;
    font-weight: 300;
    color: #abaaaa;
    margin: 15px 0 20px 0;

    .material-icons {
      font-size: 14px;
      color: $crd-primary-color;
      margin-top: -3px;
    }
  }

  .total-label {
    font-size: 13px;
    font-weight: 700;
    color: #232323;
    margin-bottom: 12px;
  }

  .total-price {
    font-size: 18px;
    font-weight: 700;
    color: $crd-secondary-color;
    margin-bottom: 15px;
  }

  .btn.btn-primary {
    width: 140px;
  }
}

.featured-products .unlogged,
.products-in-category .unlogged {
  .curaprox-links {
    display: none;
  }

  .left-div {
    margin-bottom: 15px;
  }
}

.unlogged {
  #products {
    .curaprox-links {
      display: none;
    }

    .left-div {
      margin-bottom: 15px;
    }

    .product_show_list {
      .left-div {
        margin-bottom: auto;
      }

      .curaprox-links {
        display: block;
      }

      .product-description {
        height: 140px;
        display: flex;
        align-items: center;

        .left-div {
          h1 {
            min-height: 0;
          }
        }

        .right-div {
          width: 50%;
        }
      }
    }
  }
}

/** AMAZING FILTERS **/
.af_filter {
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 30px;

  .af_subtitle {
    font-size: 15px;
    font-family: NeoSansProMedium, sans-serif;
    font-weight: 500;
    color: #222;
    line-height: 35px;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 25px;

    &:after {
      content: "";
      background: #e6e6e6;
      position: absolute;
      top: 36px;
      left: 0;
      height: 1px;
      width: 50px;
    }
  }

  li {
    margin: 0;
    padding: 0;
    line-height: 36px;
  }

  li.active {
    .name,
    .count {
      color: $crd-secondary-color;
    }
  }

  label {
    font-family: GalaxiePolarisBook, sans-serif;
    font-size: 13px;
    font-weight: 300;
    cursor: pointer;
    display: block;
    text-align: left;
    margin-bottom: 0;
  }

  .name,
  .count {
    color: #8b8b8b;
  }

  .count:before,
  .af-total-count:before {
    content: '(';
  }

  .count:after,
  .af-total-count:after {
    content: ')';
  }

  .checker,
  .af.checkbox {
    display: inline-block;
    vertical-align: top;
    margin: 4px 0 0 0;
  }

  .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    border: 3px solid $crd-secondary-color;
    background: #ebebeb;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  .ui-slider-horizontal .ui-slider-handle {
    top: -5px;
    -ms-touch-action: none;
    touch-action: none;
  }

  .ui-slider .ui-slider-range {
    height: 3px;
    background-image: none;
    border-radius: 0;
    background: $crd-secondary-color;
  }

  .ui-widget-content {
    border: none;
    background: #ebebeb;
  }

  .ui-corner-all {
    border: none;
    border-radius: 0;
  }

  .ui-widget {
    height: 3px;
  }

  .slider .slider_value {
    display: inline-block;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    margin-top: 10px;
    font-size: 11px;
    padding: 3px 9px;
  }

  .slider .slider_value.to_display {
    float: right;
  }
}
#sorting-top .js-product-list-selection {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

/** FEATURED PRODUCTS **/
#index h1.products-section-title {
  font-size: 24px;
  line-height: 30px;
  font-family: NeoSansProMedium, sans-serif;
  color: $brand-primary-title;
  margin-bottom: 90px;
  font-weight: 500;
}

/** FEATURED PRODUCTS **/

.ordered-articles {
  .category-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 10px 10px;
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(lg) {
  .af_filter {
    .count {
      display: none;
    }
  }
  #products .product_show_list {
    .pull-left {
      float: none;
    }

    .pull-right {
      float: none;
      text-align: center;
    }

    .product-description {
      .qty-box {
        padding: 0;

        .input-box {
          margin: 0 auto 15px;
        }
      }

      .add-to-fav, .history-icon {
        transform: translateY(-21px);
      }
    }

    .product-thumbnail {
      height: 140px;
      vertical-align: middle;
      display: flex;
      align-items: center;
    }
  }

  .af_filter label {
    font-size: 12px;
  }
  .af_filter .af-parent-category label,
  .af_filter.foldered .af-parent-category label {
    padding-right: 0 !important;
    max-height: 32px;
    line-height: 32px;
  }
  .af_filter .af-toggle-child {
    right: 0 !important;
  }
  .nice-checkbox .side-label {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
  }
}

@media(min-width: 767px) and (max-width: 1199px) {
  .af_filter .af-toggle-child {
    right: -21px !important;
  }
}


@include media-breakpoint-down(md) {
  .af_filter {
    .ui-slider-horizontal {
      .ui-slider-handle {
        top: -14px;
        width: 30px;
        height: 30px;
      }
    }

    .slider {
      .slider_value {
        margin-top: 21px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #category {
    #left-column {
      width: 100%;

      #search_filters_wrapper {
        margin-left: -30px;
        margin-right: -30px;
      }

      #search_filter_controls {
        text-align: center;

        button {
          margin: 0 torem(8px);
        }

        margin-bottom: torem(16px);
      }

      #search_filters {
        margin-bottom: 0;
        box-shadow: none;
        padding: 0;
        border-top: 1px solid $gray-lighter;

        .facet {
          .title {
            cursor: pointer;

            .collapse-icons .remove {
              display: none;
            }
          }

          .title[aria-expanded="true"] {
            .collapse-icons {
              .add {
                display: none;
              }

              .remove {
                display: block;
              }
            }
          }

          padding-top: 0;
          border-bottom: 1px solid $gray-lighter;

          .facet-title {
            color: $gray-darker;
            text-transform: uppercase;
          }

          .h6 {
            margin-bottom: 0;
            padding: torem(10px);
            display: inline-block;
          }

          .navbar-toggler {
            display: inline-block;
            padding: torem(10px) torem(10px) 0 0;
          }

          .collapse {
            display: none;

            &.in {
              display: block;
            }
          }

          .facet-label {
            a {
              margin-top: 0;
            }
          }

          ul {
            margin-bottom: 0;

            li {
              border-top: 1px solid $gray-lighter;
              padding: torem(10px);
            }
          }
        }
      }
    }

    #content-wrapper {
      width: 100%;
    }

    #search_filter_toggler {
      width: 100%;
    }
  }
  .products-sort-order {
    .select-title {
      margin-left: 0;
    }
  }
  .products-selection {
    h1 {
      padding-top: 0;
      text-align: center;
      margin-bottom: torem(16px);
    }

    .showing {
      padding-top: torem(16px);
    }
  }
  #prices-drop #content-wrapper,
  #new-products #content-wrapper,
  #best-sales #content-wrapper {
    width: 100%;
  }
}

@include media-breakpoint-down(xs) {
  .products-selection {
    .filter-button {
      padding-left: 0;
    }
  }
  #category {
    #left-column {
      #search_filters_wrapper {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }
  #sorting-top .js-product-list-selection {
    padding-bottom: 0;
    margin-bottom: 20px;
  }
  .products-selection {
    .amz-filter.filter-box {
      margin: 14px 0;
    }

    .sort-by-row {
      display: block;
    }
  }
}
