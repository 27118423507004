body, .mm-page, #wrapper {
  background: #f6f6f6;

  &:has(.cms-content) {
    background: white;
  }
}

#index .products-flex {
  @media (min-width: 1380px) {
    @include flexboxGridMixin(4, 40, 40);
  }
  @media (max-width: 1379px) and (min-width: 1025px) {
    @include flexboxGridMixin(3, 20, 20);
  }
  @media (max-width: 1024px) and (min-width: 601px) {
    @include flexboxGridMixin(2, 20, 20);
  }
  @media (max-width: 600px) {
    @include flexboxGridMixin(1, 0, 20);
  }
}

[class^="col-"] {
  img {
    max-width: 100%;
  }
}

[id^="products-section-"] {
  h2 {
    margin: 40px 0;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 16px;
    font-weight: bold;
    color: #000;

    a {
      font-size: 13px;
      line-height: 16px;
    }
  }
}

[id^="cms-section-"] {
  margin-top: 40px;
}

#index #wrapper {
  padding: 30px 0;
}

.banner-two, .banner-three {
  margin: 30px 0;
}

@media (max-width: 1379px) and (min-width: 1024px) {
  #index {
    [id^="products-section-"] {
      .product-block:nth-child(4) {
          display: none;
      }
    }
  }
}
@media (max-width: 450px) {
  #index #wrapper {
    padding: 15px 0;
  }
  #index {
    [id^="products-section-"] {
      h2 {
        margin: 15px 0;
        font-size: 13px;
      }
    }
  }
  [id^="cms-section-"] {
    margin-top: 15px;

    .col-sm-6:first-child {
      margin-bottom: 15px;
    }
  }
}
