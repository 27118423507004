#module-crd_consultantlocator-requestAppointment {
  .mm-page #notifications .container {
    height: 0;
    width: 50%;

    .alert-success {
      top: 30px;

      li {
        text-align: center;
      }
    }

  }

}

.language-container .rounded {
  display: none;
}

#order-confirmation #order-details .invoice-download {
  text-transform: none !important;
}

.discount-information {
  display: flex;
  margin-top: 15px;

  p {
    margin: 3px 0;
    line-height: 16px;
  }

  .discount-info {
    line-height: 16px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.product-info-wrapper {
  position: relative;
}

.discount-icon {
  background-image: url('../img/Percent_lightblue.svg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  align-self: flex-start;
}

.products .product-info-wrapper {
  position: relative;

  .discount-container {
    position: absolute;
    top: -49px;
    right: 0;

    .info {
      width: 300px;
      right: 0;
      left: auto;
      bottom: auto;
      top: 60px;
    }

    .tooltip-arrow {
      left: auto;
      right: -10px;
    }
  }
}

@media (max-width: 1080px) {
  .products .discount-container .info,
  #right-side-cart .article-content .discount-container .info {
    width: 300px;
    right: 0;
    left: auto;
    bottom: auto;
  }
  #_mobile_discount_info {
    margin-bottom: 15px;
  }
}

.fs-10 {
  font-size: 10px;
}