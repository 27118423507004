.cart-grid {
  margin-bottom: torem(16px);
}

#right-side-cart-background {
  .right-cart-summary {
    .totals {
      .right {
        text-align: right;
      }
      .notification {
        padding-top: 4px;
      }
      @media (max-width: 450px) {
        .labels.shipping-notification {
          padding-left: 15px;
        }
      }

      .labels.shipping-notification {
        color: #00afd7;
        font-size: 11px;
        font-style: italic;
        text-align: left;
        line-height: 15px;
      }
    }
  }
}

.cart-items {
  margin-bottom: 0;
}

.cart-item {
  padding: 10px 0;
  border: 1px solid $gainsboro;
  border-radius: 7px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.cart-summary-line {
  @include clearfix;
  clear: both;

  .label {
    padding-left: 0;
    font-weight: normal;
    white-space: inherit;
  }

  .value {
    color: $gray-darker;
    float: right !important;
  }

  &.cart-summary-subtotals {
    .label,
    .value {
      font-weight: normal;
    }
  }
}

.card.cart-container {
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}

.card .cart-overview {
  max-height: 474px;
  overflow-y: scroll;
  padding-right: 10px;
}

.card .cart-overview::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: #efeeee;
  border-radius: 9px;
  padding-left: 10px;
}

.card .cart-overview::-webkit-scrollbar:vertical {
  width: 18px;
}

.card .cart-overview::-webkit-scrollbar-thumb {
  border-radius: 9px;
  background-color: $crd-secondary-color;
}

#hook-display-before-carrier {
  .input-group {
    padding-left: 33px;
  }

  .input-group-addon {
    background: none;
    max-height: 34px;
    position: absolute;
    left: 0;
    z-index: 4;
    border: none;
    padding: 3px 0px 0 11px;
    color: $brand-primary;
    font-size: 20px;
  }

  #deliverydate {
    max-width: 170px;
    padding-left: 36px;
    border-radius: 3px;
  }

  .relative {
    position: relative;
    display: inline-block;
    margin-left: 20px;
  }

  #notificationemail {
    padding-left: 37px;
    width: 350px;
  }

  .form-group {
    .form-control {
      color: $prod-title;
    }
  }
}

#supercheckout-fieldset {
  .carrier {
    padding: 32px;
    margin-top: 20px;

    .highlight {
      > div {
        float: left;
      }
    }

    .shipping-header {
      h4 {
        font-weight: bold;
        color: $prod-title;
        font-size: 14px;
        padding-bottom: 14px;
        margin-bottom: 31px;
      }

      display: none;
    }

    .radiobtn-cont {
      margin-right: 22px;
      margin-top: 8px;
    }

    .shipping-logo {
      margin-right: 26px;
    }

    .radiobtn-cont {
      display: none;
    }
  }

  .custom-radiobutton, .custom-checkbox {
    display: block;
    position: relative;

    input[type=radio] + span .checkbox-checked,
    input[type=checkbox] + span .checkbox-checked {
      display: none;
      margin: -.25rem -.125rem;
      color: $gray-darker;
      font-size: 16px;
    }

    input[type=radio]:checked + span .checkbox-checked,
    input[type=checkbox]:checked + span .checkbox-checked {
      display: block;
    }

    input[type=radio] + span,
    input[type=checkbox] + span {
      display: inline-block;
      width: .9375rem;
      height: .9375rem;
      padding: 2px 0 0 1px;
      vertical-align: middle;
      cursor: pointer;
      border: 1px solid $gray-dark;
      border-radius: 3px;
      position: absolute;
      left: 0;
    }

    input[type=radio],
    input[type=checkbox] {
      opacity: 0;
      cursor: pointer;
    }
  }

  .underline {
    position: relative;

    &:after {
      content: "";
      width: 450px;
      max-width: 100%;
      border-bottom: 1px solid $gray-light;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .span-text {
    font-size: 13px;
    color: $gray;
    font-weight: bold;
    line-height: 18px;
  }

  .payment-box {
    padding: 32px;
    margin-bottom: 30px;

    input[type=radio] {
      visibility: hidden;
      position: absolute;
    }

    .select-payment {
      input[type=radio] {
        visibility: visible;
        position: static;
      }
    }

    .buttonWithProgres {
      text-align: right;
    }

    .abs-button {
      position: absolute;
      right: 0;
      top: 50%;
      text-align: right;
      transform: translateY(-18px);
    }

    p {
      font-weight: bold;
    }

    .total-price-payment-summary {
      padding-bottom: 14px;
      margin-bottom: 26px;
      text-transform: uppercase;

      p {
        margin-bottom: 0;
      }
    }

    .order-price {
      font-size: 13px;
      font-weight: bold;

      span {
        font-size: 18px;
        color: $brand-primary;
      }
    }

    .placeorderButton {
      position: absolute;
      right: 15px;
      bottom: 0;
    }
  }

  .payment-header {
    font-weight: bold;
    color: $prod-title;
    font-size: 14px;
    padding-bottom: 14px;
    margin-bottom: 26px;
  }

  .btn-primary {
    label {
      color: inherit;
      text-align: center;
      font-size: inherit;
      margin-bottom: 0;
    }
  }

  .relative {
    position: relative;
  }
}

#supercheckout-agree {
  .custom-checkbox {
    float: left;
    margin-right: 7px;

    input[type=checkbox] + span {
      top: 3px;
    }
  }
}

#supercheckout-fieldset .supercheckout-blocks.delivery-block {
  margin: 51px 0 0;
  padding: 0;

  .shipping_info {
    label {
      margin: 0;
      display: inline;
    }
  }

  .shipping-logo {
    margin-top: 8px;
  }

  .shipping_time {
    margin-bottom: 8px;
  }
}

.one-order-button {
  .btn {
    font-size: 20px;
    padding: 10px 20px;
    height: inherit;
    width: 254px;
    border-radius: 30px;
  }
}

/** CART BODY **/
.cart-grid-body {
  a.label {
    &:hover {
      color: $brand-primary;
    }
  }

  .card-block {
    padding: torem(16px);

    h1 {
      margin-bottom: 0;
    }
  }

  hr {
    margin: 0;
  }

  .cart-overview {
    padding: 0;
  }

  margin-bottom: torem(12px);
}

/** CART RIGHT **/
.cart-grid-right {
  hr {
    margin: 0;
  }

  .promo-discounts {
    margin-bottom: 0;

    .cart-summary-line {
      .label {
        color: $gray-dark;

        .code {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.block-promo {
  .promo-code {
    padding: 1.60rem;
    background: $gray-light;

    .alert-danger {
      position: relative;
      margin-top: torem(20px);
      background: $brand-danger;
      color: white;
      display: none;

      &::after {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $brand-danger;
        border-width: 10px;
        margin-left: -10px;
      }
    }
  }

  .promo-input {
    color: $gray-darker;
    border: $gray 1px solid;
    height: torem(40px);
    text-indent: torem(10px);
    width: 60%;

    + button {
      margin-top: -4px;
      text-transform: capitalize;
    }
  }

  .cart-summary-line .label,
  .promo-name {
    color: $brand-warning;
    font-weight: 600;

    a {
      font-weight: normal;
      color: $gray-darker;
      display: inline-block;
    }
  }

  .promo-code-button {
    padding-left: torem(20px);
    margin-bottom: torem(20px);
    display: inline-block;
  }

  &.promo-highlighted {
    padding: torem(20px);
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

/** CONTENT LEFT **/
.product-line-grid-left {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.cart-item.cart-title {
  padding: 20px 0;
  font-weight: 700;
  font-size: 14px;
  color: $prod-title;
  text-transform: uppercase;
  width: calc(100% - 26px);

  .product-line-grid {
    height: 12px;
  }
}

.product-line-info {
  @include vertical-align();
  margin-bottom: 0;
  display: inline-block;
  width: 100%;

  &.align-center {
    text-align: center;
  }

  &.pull-right {
    width: auto;
  }

  /** QTY UP AND DOWN **/

  .input-group {
    max-width: 100px;
    border: 1px solid $gray-light2;
    border-radius: 14px;
  }

  .bootstrap-touchspin input.form-control {
    background: white;
    border: none;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: $prod-title;
  }

  .fa.fa-angle-left,
  .fa.fa-angle-right {
    font-size: 14px;
    color: $prod-title;
    border: none;
    background: none;
    margin-top: 4px;
  }

  .fa.fa-trash-o {
    color: $gray;
    font-size: 15px;
  }
}

/** CONTENT BODY **/
.product-line-grid-body {
  > .product-line-info {
    > .label {
      padding: 0;
      line-height: inherit;
      text-align: left;
      white-space: inherit;
    }

    > .out-of-stock {
      color: red;
    }

    > .available {
      color: $brand-success;
    }

    > .unit-price-cart {
      padding-left: 0.3125rem;
      font-size: 0.875rem;
      color: $gray-dark;
    }
  }
}

/** CONTENT LEFT **/
.product-line-grid-right {
  .bootstrap-touchspin {
    width: torem(68px);
    float: left !important;
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.15);

    > .form-control,
    > .input-group {
      color: $gray-darker;
      background-color: white;
      height: $input-height;
      padding: 0.175rem 0.5rem;
      width: torem(48px);
    }

    > .input-group-btn-vertical {
      width: auto;
    }
  }

  .cart-line-product-actions,
  .product-price {
    color: $gray-darker;
    line-height: 36px;

    .remove-from-cart {
      color: $gray-darker;
      display: inline-block;
      margin-top: torem(5px);
    }
  }
}

.product-line-grid {
  height: 66px;

  > div {
    height: 100%;
  }

  .label {
    font-size: 15px;
    font-weight: 700;
    color: $brand-primary-title;
    padding-left: 15px;
  }

  .price {
    color: $crd-primary-color;
    font-size: 14px;
    font-weight: 700;
  }
}

/** CART TOTALS **/
.supercheckout-totals {
  .separator {
    border-bottom: 1px solid $gainsboro;
  }

  .title, .value {
    font-size: 13px;
    font-weight: 700;
    color: #8b8b8b;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #supercheckout_summary_total_products {
    .title {
      font-size: 13px;
      color: #222;
      font-weight: 700;
      text-transform: uppercase;
      padding-bottom: 0;
    }

    .value {
      color: $crd-primary-color;
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 0;
    }
  }
}

.align-right {
  text-align: right;
}

.cart-label-subtitle, #checkoutBillingAddress .sc-title, #checkoutShippingAddress .sc-title {
  font-size: 18px;
  color: #222;
  line-height: 18px;
  padding-bottom: 13px;

  a {
    color: #222;
  }
}

.address-rows {
  border-radius: 5px;
  border: 1px solid $gainsboro;
  padding: 20px 30px;
  margin-top: 30px;
  min-height: 260px;
}

.totals-rows {
  border-radius: 5px;
  border: 1px solid $gainsboro;
  padding: 20px 30px;
  margin-top: 30px;
  min-height: 260px;

  &:after {
    clear: both;
    float: none;
  }
}

.supercheckout_address_detail {
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 15px;
}

.supercheckout_address_detail.padding-top {
  padding-top: 15px;
}

#supercheckout-fieldset {
  .input-group-addon {

  }
}

#supercheckout-comments {
  .comments-header {
    font-weight: 700;
    color: $prod-title;
    font-size: 14px;
    padding-bottom: 14px;
  }

  #supercheckout-comment_order {
    &:focus {
      outline: none;
      /* box-shadow: inset 0 0 3px 2px $crd-secondary-color;*/
    }
  }
}

.order-message-form {
  label {
    text-align: left;
  }

  .form-group .form-control {
    height: inherit;
  }
}

#gritter-notice-wrapper {
  p {
    color: white;
  }

  .gritter-success {
    background: $crd-secondary-color;
  }
}

.supercheckout-blocks {
  .cart-item {
    a.label {
      display: block;
    }
  }
}

.m-l-r-15,
.m-l-r-15.no-gutters {
  margin-left: 15px;
  margin-right: 15px;
}

.row.m-l-r-0 {
  margin-left: 0;
  margin-right: 0;
}

.product-line-info.float-right {
  width: initial;
}

/** END CART TOTALS **/

.fewitems, .noitems {
  text-align: left;
  max-width: 100%;
  margin-top: 0;
}

.back-btn .btn-primary:not([href]):not([tabindex]) {
  color: white;
}

.right-side-cart {
  #supercheckout_voucher_input_row #voucher-form .voucher-container {
    position: relative;
    width: 100%;
    padding: 0;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    #discount_name.form-control {
      width: 100%;
      color: #8b8b8b;
      height: 39px;
      background: #ffffff;
      border: solid 2px #d1d1d1;
      border-right: 0;
      font-size: 12px;
      font-weight: bold;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      display: inline-block;
      padding: 0 10px 0 15px;

      &:focus {
        outline: none;
        background: #ffffff;
      }
    }

    .button-coupon-container {
      display: inline-block;
      height: 39px;
      position: relative;

      #button-coupon {
        height: 39px;
        width: auto;
        border-radius: 0 20px 20px 0;
        border: solid 2px $crd-primary-color;
        line-height: 35px !important;
        text-transform: none;
        font-size: 15px;

        &:hover {
          background: $crd-secondary-color;
          border-color: $crd-secondary-color;
        }
      }

      #button-coupon.voucher-button-disable {
        color: rgba(0, 0, 0, 0);
      }
    }
  }

  .voucher-loader-visible {
    position: absolute;
    right: calc(50% - 25px);
    bottom: -5px;
    background-image: url('../img/icons/voucher-loader.svg');
    background-size: 50px;
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(lg) {
  .address-rows {
    .ship-blk {
      margin-bottom: 20px;
    }
  }
  .product-line-info {
    .fa-shopping-cart {
      height: 30px;
    }
  }
  #hook-display-before-carrier {
    .relative {
      display: block;
      margin: 6px 0 0;
    }

    #notificationemail {
      width: 100%;
    }
  }
  #product h1 {
    margin-top: 0;
  }
  #supercheckout-fieldset {
    .payment-box {
      padding-bottom: 54px;

      .placeorderButton {
        left: calc(50% + 15px);
        right: auto;
        bottom: -42px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .product-line-grid {
    height: 86px;
  }
  .product-line-info {

  }
  #supercheckout-fieldset {
    .label, label {
      text-align: left;
    }

    #hook-display-before-carrier .relative {
      margin-bottom: 20px;
    }

    .supercheckout-blocks.delivery-block {
      margin: 20px 0 0;
      padding: 29px 0;
    }
  }
  .limit-text {
    height: 67px;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 810px) {
  #supercheckout-fieldset {
    .payment-box {
      .total-price-payment-summary {
        margin-top: -17px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .product-line-grid-body {
    margin-bottom: torem(16px);
  }
  .address-rows, .totals-rows {
    padding: 20px 15px;
  }
  .product-line-grid {
    height: inherit;
  }
  .cart-item.cart-title {
    display: none;
  }
  .product-line-info .fa-shopping-cart {
    height: inherit;
  }
  #supercheckout-fieldset {
    .payment-box {
      padding: 30px;

      .total-price-payment-summary {
        margin-top: 25px;
      }
    }

    .supercheckout-blocks, .blocks {
      width: 100%;
    }

    .payment-box .placeorderButton {
      position: static;
      margin-top: 15px;
    }
  }
  .product-line-info {
    position: static;
    transform: none;
  }
  .reorder-btn {
    margin: 15px 0 0;
    display: inline-block;
  }
}

@include media-breakpoint-down(xs) {
  .cart-items {
    padding: torem(16px) 0;
  }
  .cart-grid-body {
    .cart-overview {
      padding: 0;
    }

    .no-items {
      padding: torem(16px);
      display: inline-block;
    }
  }
  .product-line-grid-left {
    padding-right: 0 !important;
  }
}

@media (max-width: 360px) {
  .product-line-grid-right {
    .qty {
      width: 100%;
    }

    .price {
      width: 100%;
    }
  }
}

@include media-breakpoint-up(md) {
  .product-line-grid {
    .prod-img-cont {
      padding-right: 0;
    }
  }
}
